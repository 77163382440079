<template>
  <div>

    <app-collapse
      accordion
      type="margin"
    >

      <app-collapse-item title="Import Multi Companies Data">
        <b-row>
          <b-col md="12">
            <h6>Upload Companies Link CSV File</h6>
            <b-form-file
              v-model="selectedCompaniesLinksFile"
              accept=".csv"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="12"
            class="text-center mt-2"
          >
            <b-button
              :disabled="!selectedCompaniesLinksFile || loading"
              variant="primary"
              @click="scrapeCrunchbaseDataAction"
            >
              <template v-if="loading">
                <b-spinner small />
                Importing...
              </template>
              <template v-else>
                Import Data
              </template>
            </b-button>
          </b-col>
        </b-row>
      </app-collapse-item>

      <app-collapse-item title="Import From Single Link">
        <b-input-group>
          <b-form-input
            v-model="link"
            placeholder="Crunchbase Link"
          />
          <b-input-group-append>
            <b-button
              :disabled="!link || loading"
              variant="outline-primary"
              @click="importDataFromLink"
            >
              <template v-if="loading">
                <b-spinner small />
                Importing...
              </template>
              <template v-else>
                Import
              </template>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </app-collapse-item>

      <app-collapse-item title="Import From Exported CSV Files">

        <b-card-code title="Import Companies Data">
          <b-row>
            <b-col md="6">
              <h6>Select Scrapped Website</h6>
              <b-form-group>
                <v-select
                  v-model="selectedCompaniesWebsite"
                  label="title"
                  placeholder="Website"
                  :options="websites"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <h6>Upload Scrapped File</h6>
              <b-form-file
                v-model="selectedCompaniesFile"
                :disabled="!selectedCompaniesWebsite"
                accept=".csv"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="text-center mt-2"
            >
              <b-button
                variant="primary"
                :disabled="!selectedCompaniesWebsite || !selectedCompaniesFile"
                @click="importData"
              >
                Import Data
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>

        <b-card-code title="Import Companies Employees">
          <b-row>
            <b-col md="6">
              <h6>Select Scrapped Website</h6>
              <b-form-group>
                <v-select
                  v-model="selectedEmployeesWebsite"
                  label="title"
                  placeholder="Website"
                  :options="[{ title: 'Crunchbase' },{ title: 'Keepface' }]"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <h6>Upload Scrapped File</h6>
              <b-form-file
                v-model="selectedEmployeesFile"
                :disabled="!selectedEmployeesWebsite"
                accept=".csv"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="text-center mt-2"
            >
              <b-button
                variant="primary"
                :disabled="!selectedEmployeesWebsite || !selectedEmployeesFile"
                @click="importEmployeesData"
              >
                Import Data
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>

        <b-card-code title="Import Companies Industries">
          <b-row>
            <b-col md="6">
              <h6>Select Scrapped Website</h6>
              <b-form-group>
                <v-select
                  v-model="selectedIndustriesWebsite"
                  placeholder="Website"
                  label="title"
                  :options="websites"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <h6>Upload Scrapped File</h6>
              <b-form-file
                v-model="selectedIndustriesFile"
                :disabled="!selectedIndustriesWebsite"
                accept=".csv"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="text-center mt-2"
            >
              <b-button
                variant="primary"
                :disabled="!selectedIndustriesWebsite || !selectedIndustriesFile"
                @click="importIndustriesData"
              >
                Import Data
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>

      </app-collapse-item>
    </app-collapse>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormFile, BButton, BFormInput, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormFile,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    AppCollapse,
    AppCollapseItem,

    BCardCode,
    vSelect,
  },
  data() {
    return {
      link: null,
      loading: false,
      websites: [
        { title: 'Crunchbase' },
        { title: 'Influence.co' },
      ],
      selectedFile: null,
      selectedWebsite: null,
      selectedEmployeesFile: null,
      selectedCompaniesFile: null,
      selectedIndustriesFile: null,
      selectedCompaniesWebsite: null,
      selectedEmployeesWebsite: null,
      selectedIndustriesWebsite: null,
      selectedCompaniesLinksFile: null,
    }
  },
  methods: {
    ...mapActions('settings', ['importCompaniesAction', 'importEmployeesAction', 'importIndustriesAction', 'scrapeCrunchbaseData', 'scrapeCrunchbaseLink']),
    importDataFromLink() {
      this.loading = true
      this.scrapeCrunchbaseLink({ link: this.link }).then(({ data }) => {
        this.showToast(data.data.message, 'success')
      }).finally(() => {
        this.loading = false
      })
    },
    scrapeCrunchbaseDataAction() {
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.selectedCompaniesLinksFile)
      formData.append('website', 'Crunchbase')

      this.scrapeCrunchbaseData(formData).finally(() => {
        this.loading = false
      })
    },
    importData() {
      const formData = new FormData()
      formData.append('file', this.selectedCompaniesFile)
      formData.append('website', this.selectedCompaniesWebsite.title)

      this.importCompaniesAction(formData)
    },
    importEmployeesData() {
      const formData = new FormData()
      formData.append('file', this.selectedEmployeesFile)
      formData.append('website', this.selectedEmployeesWebsite.title)

      this.importEmployeesAction(formData)
    },
    importIndustriesData() {
      const formData = new FormData()
      formData.append('file', this.selectedIndustriesFile)
      formData.append('website', this.selectedIndustriesWebsite.title)

      this.importIndustriesAction(formData)
    },
  },
}
</script>
